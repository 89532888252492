@import "./index.scss";
@import "./constants.scss";

header {
    position: absolute;
    width: 100%;
    //box-shadow:  inset 0px 7px 3px -3px rgba(50, 50, 50, 0.75);
    //background: linear-gradient(180deg, black 0%, transparent, rgba(255, 255, 255, 0) 20%, transparent, rgba(255, 255, 255, 0) 100%, transparent) fixed;
    z-index: 3;
}

.logo {
    width: 263px;
}

.logo_AdPremier {
    margin-left: 10px;
    width: 204px;
    height: 49px;
}

.Logo_google_partner_footer {
    margin-left: 11px;
    margin-top: 20px;
}

.header_logo {
    @extend .logo;
    $marginTop: 20px;
    margin-top: $marginTop;
}

.content {
    min-height: calc(100vh - #{$footer_height});
}

.content_container_full {
    width: 100%;
    height: 100%;
    padding: 0 5%;
    margin: 0 auto;
    border-top: 75px solid transparent;
    border-bottom: 75px solid transparent;
    background-color: white;
    word-break: break-word;

    @media screen and (max-width: 749px) {
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
    }
}

.content_container_full_bg {
    @extend .content_container_full;
    background-color: #f0f2f7;
}

.content_container {
    width: 100%;
    padding: 0 5%;
    margin: 0 auto;
}

.content_container {
    &_sm {
        padding: 0 5%;
    }
    &_md {
        padding: 0 10%;
    }
    &_lg {
        padding: 0 20%;

        @media screen and (max-width: 500px) {
            padding: 0 10%;
        }
        @media screen and (max-width: 1024px) {
            padding: 0 15%;
        }
    }
}

.content_container_row {
    width: 100%;
    height: 100%;
    min-height: 200px;
    margin: 0px auto;
    border-top: 100px solid transparent;
    border-bottom: 100px solid transparent;
}

.header_container {
    width: 100%;
    padding: 0 5%;
    margin: 0 auto;
    height: 100%;

    //@media screen and (max-width: 767px) {
    //    padding-top: ;
    //}
}

.header_content {
    @extend .w-100, .d-flex, .justify-content-between, .align-items-center;

    @media screen and (max-width: 600px) {
        flex-wrap: wrap;
        justify-content: center !important;
    }

    @media screen and(max-width: 1015px) {
        flex-direction: column;
    }
}

.header_right_section {
    @extend .d-flex, .justify-content-between;

    @media screen and (max-width: 1014px) {
        flex-wrap: wrap;
        justify-content: center !important;
    }

    @media screen and (max-width: 600px) {
        justify-content: center !important;
    }
}

.language_panel {
    @extend .d-flex, .justify-content-between;

    align-items: baseline;
    margin: 0 40px;
    margin-top: $header_margin_top;

    @media screen and (max-width: 1138px) {
        margin: 0 20px;
        margin-top: $header_margin_top;
    }

    @media screen and (max-width: 1014px) {
        order: 1;
        align-items: center;
        margin-top: 30px;
    }

    & button {
        display: flex;
        align-items: center;

        & img {
            width: 20px;
            margin-right: 5px;
        }
    }
}

.btn_change_language {
    background: none;
    width: 60px;
    &_active {
        background: none;
        width: 60px;
    }

    & span {
        color: white;
        text-transform: uppercase;
        font-size: 16px;
    }

    &:hover span,
    &_active span {
        color: white;
        text-transform: uppercase;
        text-decoration: underline;
        font-size: 16px;
    }
}

.navbar_main {
    @extend .align-items-baseline;
    padding: 0;
    margin-right: 50px;

    &_link {
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        line-height: 20px;
        display: block;
        color: white;
        padding: $header_margin_top 10px 10px 10px;
        height: 40px;
        box-sizing: content-box;

        @media screen and (min-width: 1920px) {
            font-size: 20px;
        }

        @media screen and (max-width: 1138px) {
            font-size: 16px;
        }

        @media screen and (max-width: 1014px) {
            padding: 20px 10px 5px;
            //margin-top: 10px;
        }

        &::first-letter {
            text-transform: uppercase;
        }

        &:hover {
            color: $yellow;
        }

        &.active_page {
            background-color: #f2bb34;
            color: black;
            text-decoration: none;
            border-radius: 0px 0px 20px 20px;
        }
    }

    @media screen and (max-width: 1138px) {
        margin-right: 20px;
    }

    @media screen and (max-width: 1014px) {
        width: 100%;
        margin-right: 0;

        ul {
            margin-right: auto !important;
            margin-left: auto !important;
            flex-direction: row !important;
        }
    }

    @media screen and (max-width: 1015px) {
        ul {
            margin-left: auto !important;
            margin-right: auto !important;
            border-top: 1px solid $yellow;
        }
    }
}

.navbar_footer {
    padding: 0;

    &_list {
        flex-direction: column !important;

        @media screen and (max-width: 500px) {
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }

    &_link {
        font-size: 18px;
        display: block;
        color: inherit;
        padding: 5px;
        padding-left: 10px;
        border-left: 2px solid #f2bb34;

        @media screen and (min-width: 1920px) {
            font-size: 20px;
        }

        &::first-letter {
            text-transform: uppercase;
        }

        &:hover {
            color: $yellow;
        }

        &.active_page {
            background-color: #f2bb34;
            color: black;
            text-decoration: none;
            border-radius: 0px 20px 20px 0px;
        }
    }
}

.link_yellow {
    color: $yellow;
    font-size: 18px;
    line-height: 20px;
    margin-right: 10px;
    width: 110px;
    text-align: right;

    &:hover {
        color: $yellow;
        text-decoration: none;
    }

    @media screen and (max-width: 1014px) {
        font-size: 16px;
    }
}

.account_panel {
    @extend .d-flex, .justify-content-between, .align-items-center;
    margin-top: calc(#{$header_margin_top} - 23px);
    position: relative;

    // &:hover &_menu {
    //     display: block;
    // }

    @media screen and (max-width: 1014px) {
        margin-top: 44px;
        order: 2;
        width: fit-content;
        justify-content: center !important;
        margin-top: 30px;
    }

    &_nav {
        img {
            width: 40px;
            height: auto;
        }
        cursor: pointer;
    }
    &_menu_wrapper {
        display: none;
        position: absolute;
        right: 0;
        z-index: 9999;

        @media screen and(max-width: 1010px) {
            left: -80px;
        }

        @media screen and (max-width: 345px) {
            left: -50px;
        }
    }
    &_menu_blanc {
        width: 100%;
        height: 30px;
    }
    &_menu {
        border-radius: 20px 0px 20px 20px;
        width: 250px;
        background-color: white;
        cursor: default;

        &_list {
            cursor: default;
            padding: 30px 40px;
        }
        &_item {
            cursor: pointer;
            display: block;
            width: 100%;
            background-color: transparent;
            outline: none;
            padding: 5px 0;
            text-decoration: none;
            text-align: center;
            color: $blue_dark;
            font-weight: 500;

            &::first-letter {
                text-transform: uppercase;
            }

            &:hover {
                text-decoration: none;
                color: $yellow;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #dde1eb;
            }
        }
    }
}

.footer {
    color: white;
    background: $purple;
    padding: 60px 0 30px 0;
    min-height: 345px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .footer_content {
        @extend .w-100, .d-flex, .justify-content-between, .align-items-start, .flex-wrap;

        @media screen and (max-width: 1014px) {
            & > button {
                margin: 0 auto;
            }
        }

        @media screen and (max-width: 500px) {
            justify-content: center !important;
            & > * {
                width: 100%;
                text-align: center;
            }
        }
    }

    .footer_btns {
        display: grid;
        gap: 10px;
    }
}

.copyright {
    width: 100%;
    font-size: 18px;
    text-align: center;
    color: inherit;
    margin-top: 40px;

    a {
        color: inherit;
        text-decoration: underline;
    }
}

.footer_logos {
    @extend .d-flex, .align-items-baseline, .flex-column;

    @media screen and (max-width: 1014px) {
        align-items: center !important;
        margin-bottom: 20px;
    }

    @media screen and (max-width: 500px) {
        align-items: center !important;
        margin-bottom: 20px;
    }
}

.page_header {
    @extend .d-flex, .justify-content-center, .flex-column;

    // background-image: url(../images/background/secondary_header_bg.png);
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    height: 525px;
    padding-top: 120px;
    position: relative;
    z-index: 2;

    @media screen and (max-width: 1015px) {
        padding-top: 250px;
    }

    @media screen and (max-width: 360px) {
        padding-top: 280px;
    }

    &_title {
        font-size: 64px;
        line-height: 75px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: 800;

        @media screen and (max-width: 1138px) {
            font-size: 50px;
            line-height: 65px;
        }
        @media screen and (max-width: 1014px) {
            font-size: 35px;
            line-height: 40px;
        }

        @media screen and (max-width: 500px) {
            padding: 0 10px;
        }
    }
    &_description {
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: $gray;
        padding: 0 15%;

        @media screen and (max-width: 1138px) {
            font-size: 16px;
            line-height: 18px;
        }
    }
}

.breadcrumbs_panel {
    position: absolute;
    top: $header_height;
    padding: 20px 5%;

    @media screen and (max-width: 1015px) {
        top: 0px;
        left: 0;
        right: 0;
        text-align: center;
        position: relative;
    }

    &_item {
        color: #948b9f;
        font-size: 16px;
        line-height: 18px;
        text-transform: capitalize;

        &:not(:last-child):after {
            content: "/";
            margin: 0 10px;
            font-family: sans-serif;
        }

        &:hover:not(:last-child) {
            color: $gray_dark;
            text-decoration: none;
        }

        &:last-child,
        &:hover:last-child {
            color: #948b9f;
            font-weight: bold;
            cursor: default;
        }
    }
}

.page_tariffs_bottom_bg {
    display: flex;
    // background-image: url(../images/background/bottom_bg_tariffs.png);
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
    min-height: 400px;
}

.tariffs_wrapper {
    margin: 50px 0;
}

.tariff_group {
    margin: 100px 0;

    &_title {
        &__light {
            @include section_title;
            margin-bottom: 30px;
            // padding-right: 30%;
            color: $yellow;
        }
        &__dark {
            @include section_title;
            margin-bottom: 30px;
            // padding-right: 30%;
        }
    }
    &_description {
        &__light {
            @include section_description;
            margin-bottom: 30px;
            // padding-right: 30%;
            color: white;
        }
        &__dark {
            @include section_description;
            margin-bottom: 30px;
            // padding-right: 30%;
        }
    }
    &_items {
        @extend .d-flex, .justify-content-center, .flex-wrap;
    }
}

.tariff_item {
    @include box-shadow-green;
    background: $gray;
    padding: 50px 5% 30px;
    width: 100%;
    min-width: 200px;
    max-width: 400px;
    margin: 30px auto;
    position: relative;

    &_title {
        @include section_title;
        font-size: 34px;
        margin-bottom: 20px;
        text-align: center;
        text-transform: uppercase;
    }
    &_description {
        @include section_description;
        margin-bottom: 30px;
        text-align: center;
        color: $gray_dark;
    }
    &_points {
        margin: 20px auto;
        list-style-position: outside;
        width: 100%;
        max-width: 350px;
        padding-left: 17px;
    }
    &_btn {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: -22.5px;
        left: calc(50% - 102.5px);
    }
}

.loading_indicator {
    &_absolute {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &_fixed {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.btns_panel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 50px;

    & > *:not(:last-child) {
        margin-right: 10px;
    }
}

.searchbar {
    width: 200px;
    position: relative;

    &__transparent {
        width: 100%;

        @media screen and (max-width: 520px) {
            max-width: 230px;
        }

        @media screen and (max-width: 375px) {
            max-width: 175px;
        }

        @media screen and (max-width: 320px) {
            max-width: 150px;
        }
    }

    & input {
        width: 200px;
        background-color: #f0f2f7;
        padding: 10px 20px;
        border-radius: 10px;
        border: 0;
        height: 40px;
        font-size: 12px;
        line-height: 14px;
    }

    &__transparent input {
        width: 100%;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        padding: 5px 20px;
        padding-right: 34px;
        border-radius: 8px;
        height: 30px;
        font-size: 12px;
        line-height: 14px;
    }

    &_btn_clear,
    & img {
        position: absolute;
        right: 20px;
        z-index: 6;
        top: calc(50% - 7px);
        height: 14px;
        display: flex;
        opacity: 0.3;

        & span {
            line-height: 14px;
        }
    }
}

.modal_body_inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;

    & > img {
        width: 150px;
        height: 150px;
        margin-top: 10px;
        margin-right: 40px;
        margin-bottom: 40px;

        @media screen and (max-width: 500px) {
            margin-right: 0;
        }
    }

    button[class="close"] {
        padding-bottom: 0px;

        & span {
            font-size: 32px;
            line-height: 32px;
            font-family: serif;
        }
    }

    & > img + div {
        width: calc(100% - 190px);
        min-width: 300px;
        font-weight: bold;

        @media screen and (max-width: 500px) {
            width: 100%;
            min-width: 200px;
        }
    }

    & > img + div > div:first-child {
        color: $blue_dark;
        font-size: 22px;
        line-height: 22px;
        margin-bottom: 30px;

        @media screen and (max-width: 500px) {
            text-align: center;
        }
    }

    & > img + div > div:last-child {
        margin-top: 40px;
        display: flex;
        margin-bottom: 40px;

        & > * {
            margin-right: 30px;

            @media screen and (max-width: 500px) {
                margin-right: 0;
            }
        }
    }
}

.input_wrapper {
    position: relative;

    & img {
        position: absolute;
        left: 20px;
        z-index: 6;
        top: calc(50% - 7px);
        height: 14px;
    }

    & input {
        padding-left: 57px;
    }
}

.input {
    background-color: #f0f2f7;
    padding: 10px 20px;
    border-radius: 10px;
    border: 0;
    height: 48px;
    font-size: 12px;
}

.loading_indicator_block_all {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.tooltip_info_btn {
    color: #f2bb37;
    text-transform: lowercase;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border: 2px solid #f2bb37;
    border-radius: 50px;
    cursor: pointer;
}

.tooltip {
    border: 1px solid #f2bb37 !important;
}
