@import "./constants.scss";

/* COMMON */
.button {
    max-width: 100%;
    font-size: 14px;
    line-height: 18px;
    padding: 10px;
    color: black;
    text-align: center;
    display: inline-grid;
    grid-auto-flow: column;
    align-items: center;
    position: relative;
    justify-content: center;
    border-radius: 20px;
    white-space: normal;
    gap: 5px;

    &:disabled {
        cursor: not-allowed;
        color: $gray_dark;
        background-color: $gray;
        border: 1px solid $gray;
    }

    & > div[class="spinner-border-sm spinner-border"] {
        position: absolute;
        right: 20px;
    }

    @media screen and (max-width: 1138px) {
        padding: 8px;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
        font-size: 12px;
        line-height: 16px;
    }
}

.btn_upper {
    text-transform: uppercase;
}

@mixin colored_btn($color, $bg_light, $bg, $hover_b: transparent) {
    background-color: $bg;
    color: $color;
    border: 1px solid $bg;
    transition: background 0.4s;
    padding: 10px 30px;
    font-weight: bold;

    &:hover:not(:disabled) {
        color: $bg;
        background-color: $hover_b;
        transition: background 0.4s;
    }

    &:active,
    &:visited {
        color: $bg !important;
        background-color: $bg_light !important;
        transition: background 0.4s;
    }

    @media screen and (max-width: 500px) {
        padding: 10px 15px;
    }
}

.btn_blue {
    @extend .button, .btn_upper;
    @include colored_btn(white, $blue_light, $blue);
}

.btn_yellow {
    @extend .button, .btn_upper;
    @include colored_btn(black, $yellow_light, $yellow);
}

.btn_red {
    @extend .button, .btn_upper;
    @include colored_btn(white, $red_light, $red);
}

.btn_pink {
    @extend .button, .btn_upper;
    @include colored_btn(white, $pink_light, $pink);
}

.btn_green {
    @extend .button, .btn_upper;
    @include colored_btn(white, $green_light, $green, $green_light);
}

.btn_sm {
    font-size: 14px;
    padding: 5px 14px;
    line-height: 14px;
    min-height: 29px;
}

.btn_md {
    font-size: 14px;
    padding: 8px 20px;
}

.btn_capitalize {
    text-transform: capitalize;
}

.btn_first_upper {
    text-transform: lowercase;

    &::first-letter {
        text-transform: uppercase;
    }
}

.btn_up {
    position: fixed;
    left: 20px;
    bottom: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #6f5d8c;
    border: 5px solid #fff;
    color: #fff;
    transition: all 0.3s ease;

    &:hover {
        box-shadow: 1px 0px 15px 13px rgba(0, 81, 189, 0.8);
        transition: all 0.3s ease;
    }
}

.btn_fixed_w {
    width: 250px;
}

.btn_img {
    background-color: transparent;
    font-size: 14px;
    padding: 5px 0px;
    transition: all 0.3s;

    &:hover:not(:disabled) {
        opacity: 0.5;
        transition: all 0.3s;
    }

    &:disabled {
        opacity: 0;
    }
}

/* CUSTOM */

.btn_header_audit {
    @extend .button, .btn_yellow, .btn_upper;
    width: 205px;
    border-radius: 20px;
}

.btn_header_analize {
    @extend .btn_yellow, .btn_upper;
    font-weight: bold;
}

.btn_link_centred {
    @extend .button, .btn_yellow, .btn_capitalize, .btn_fixed_w;
    display: block;
    margin: 0 auto;
}
