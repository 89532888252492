@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@font-face {
    font-family: 'AvenirNext';
    src: local('Avenir Next Regular'), local('Avenir-Next-Regular'),
    url('./fonts/AvenirNextCyr-Regular.woff2') format('woff2'),
    url('./fonts/AvenirNextCyr-Regular.woff') format('woff'),
    url('./fonts/AvenirNextCyr-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
