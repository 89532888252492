$yellow_light: #f8dd9b;
$yellow: #f2bb37;
$blue_light: #c1c4e7;
$blue: #2f3caf;
$blue_dark: #363565;
$gray:#e0e0e0;
$gray_dark:#6d6f80;
$purple: #6f5d8c;
$red: #cc5041;
$red_light:#d59b94;
$pink: #ff3966;
$pink_light:#ff8fa8;
$green: #61b078;
$green_light:#b7dcc2;

$footer_height: 345px;
$header_height: 120px;
$header_margin_top: 50px;

@media screen and (max-width: 1014px) {
    $header_margin_top: 0px;
}

@mixin section_title {
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    color: black;

    @media screen and (max-width: 500px) {
        font-size: 30px;
    }
}

@mixin section_description {
    font-size: 18px;
    line-height: 21px;
}

@mixin box-shadow-green {
    transition: box-shadow 0.2s linear;

    &:hover {
        transition: box-shadow 0.2s linear;
        box-shadow: 0px 0px 10px rgba(0, 236, 188, 0.5);
    }
}

@mixin box-shadow {
    transition: box-shadow 0.2s linear;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    &:hover {
        transition: box-shadow 0.2s linear;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }
}
